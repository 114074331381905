export default {
    ENVIRONMENT: "production",

    ENVIRONMENTS: {
        production: {
            API_URL: "https://api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect"
        },

        development: {
            API_URL: "https://dev-api-connect.digitalrange.com",

            BASE_IMG_PATH: "https://s3.amazonaws.com/admin.digitalrange.dev"
        }
    }
};
